<template>
  <div
    v-show="headerVisible"
    class="font-weight-bold pb-1 cursor-pointer"
    @click="handleBackButtonClick"
  >
    <b-button
      variant="white"
      class="btn-icon rounded-circle text-danger bg-white mr-1 "
      size="sm"
    >
      <feather-icon
        class="text-primary"
        icon="ChevronLeftIcon"
      />
    </b-button>
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    headerVisible() {
      return this.$route.meta?.headerVisible !== false
    },
    title() {
      return this.$route.meta?.title || ''
    },
  },
  methods: {
    handleBackButtonClick() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <layout-vertical>
    <!-- <div>
      <Header />
    </div> -->
    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Header from '@/layouts/components/Header.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    Header,
    LayoutVertical,
    Navbar,
  },
}
</script>

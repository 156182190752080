import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function useVerticalNavMenu(props) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // isVerticalMenuPinned
  // ------------------------------------------------
  const isVerticalMenuPinned = computed({
    get: () => store.state.verticalMenu.isVerticalMenuPinned,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_PINNED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => (isVerticalMenuPinned.value ? 'pinned' : 'unpinned'))

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
    if (!isVerticalMenuPinned.value) {
      isVerticalMenuCollapsed.value = !val
    }
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
  }

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    isVerticalMenuPinned,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
  }
}
